<template>
  <div class="news" :style="{ backgroundImage: `url(${bannerImage})` }">
    <div class="container">
      <div class="tab-bar">
        <div
          class="tab"
          @click="active = 'new'"
          :class="active === 'new' ? 'active' : ''"
        >
          {{ L("全部") }}
        </div>
        <!-- <div
          class="tab"
          @click="active = 'old'"
          :class="active === 'old' ? 'active' : ''"
        >
          {{ L("往年") }}
        </div> -->
      </div>

      <Loading :loading="loading" />
      <template v-if="!loading">
        <div v-if="filteredList.length > 0">
          <div
            class="news-info"
            v-for="(it, index) in filteredList"
            :key="index"
          >
            <img
              class="news-image pointer"
              :src="it.face_file"
              alt=""
              @click="route(it)"
            />
            <div class="news-content">
              <div class="news-header">
                <div class="news-title pointer" @click="route(it)">
                  {{ L(it.title) }}
                </div>
                <div class="news-time">
                  {{ it.create_at | timeTrans("yyyy-MM-dd") }}
                </div>
              </div>
              <div class="news-description">{{ it.brief }}</div>
              <button class="detail" @click="route(it)">
                {{ L("查看详情") }}
              </button>
            </div>
          </div>
        </div>
        <div v-else style="padding-bottom: 16px">
          <a-empty />
        </div>

        <div class="center" v-if="total > 10">
          <a-pagination v-model="current" :pageSize="pageSize" :total="total" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/news";
export default {
  data() {
    return {
      active: "new",
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      bannerImage: "",
    };
  },
  computed: {
    filteredList() {
      return this.list;
      // const year = new Date().getFullYear();

      // if (this.active === 'new') {
      //       return this.list.filter((item) => {
      //     const date = new Date(Number(item.create_at) * 1000);
      //     return date.getFullYear() === year;
      //   });
      // }
      // else if (this.active === "old") {
      //   const year = new Date().getFullYear();
      //   return this.list.filter((item) => {
      //     const date = new Date(Number(item.create_at) * 1000);
      //     return date.getFullYear() !== year;
      //   });
      // } else {
      //   return this.list;
      // }
    },
  },

  watch: {
    current() {
      this.getList();
    },
  },

  mounted() {
    this.bannerImage = window.localStorage.getItem("banner_image");

    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;

      this.loading = true;
      fetchList({
        page: current,
        rows: pageSize,
        category: "NEWS",
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
            console.log("total", Number(res.totalSize));
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    route(it) {
      this.$router.push(this.$route.path + "/detail?code=" + it.code);
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  padding-top: 27vw;
  // background-image: url("https://s.upapi.cn/2022/05/13/e78afd8a6d69567f81113fb125c8dbad/newbanner.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #f1f1f1;
}

.news .container {
  background: #ffffff;
  border-radius: 45px 45px 0px 0px;
  padding: 24px 48px;
}

.tab-bar {
  margin-bottom: 44px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}
.tab-bar .tab {
  margin-right: 60px;
  cursor: pointer;
}
.tab-bar .tab.active {
  color: rgba(0, 0, 0, 0.8);
  position: relative;
}
.tab-bar .tab.active::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -16px;
  height: 2px;
  background-color: #000;
}

.news-info {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.news-info .news-image {
  width: 236px;
  height: 134px;
  margin-right: 16px;
}
.news-info .news-content {
  flex: 1;
}
.news-info .news-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.news-info .news-title {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  opacity: 0.8;
}
.news-info .news-time {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  opacity: 0.5;
}
.news-info .news-description {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-info .detail {
  width: 120px;
  height: 32px;
  line-height: 32px;
  background: #ffffff;
  border: 1px solid #004BB0;
  font-size: 14px;
  font-weight: 400;
  color: #004BB0;
  cursor: pointer;
}
.news-info .detail:hover {
  background-color: #004BB0;
  color: #fff;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .news .container {
    background: #ffffff;
    border-radius: 24px 24px 0 0;
    padding: 12px;
  }

  .tab-bar {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
  }
  .tab-bar .tab {
    margin-right: 16px;
    cursor: pointer;
  }
  .tab-bar .tab.active {
    color: rgba(0, 0, 0, 0.8);
    position: relative;
  }
  .tab-bar .tab.active::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: -6px;
    height: 2px;
    background-color: #000;
  }

  .news-info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .news-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .news-info .news-image {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .news-info .news-content {
    flex: 1;
  }
}
</style>